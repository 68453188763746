import request from '@/utils/request'
const API = '/api/records_analytics'

export function RecordsAnalytics(params = {}, method = 'get', subpath) {
  var url = params.id ? API + '/' + params.id : API
  url = subpath ? url + '/' + subpath : url

  var payload = {
    url: url,
    method: method,
    params
  }

  if (method === 'post' || method === 'put' || method === 'delete') {
    payload = {
      url: url,
      method: method,
      data: params
    }
  }

  return request(payload)
}
