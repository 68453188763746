<template>
  <div class="after-loop" style="margin: 20px;">
    <el-row style="margin: -50px 0 8px;">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <h4>Dashboard</h4>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
        <el-button
          v-if="records"
          type="primary"
          size="mini"
          style="border-radius: 5px; font-size: 14px;"
          @click="viewFile()">
          Manual
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <h4>Welcome, {{ $store.getters.user.currentUser.personnel.realname }}!</h4>
    </el-row>
    <!-- Records -->
    <el-row v-if="records" :gutter="20">
      <!--
      // Hide Minutes
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'MINUTES' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.minutes }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      -->
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'RESOLUTIONS' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.resolutions }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Resolutions</p>
            <span class="i-icon"><i class="fa fa-vote-yea"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'ANNUALREPORTS' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.annual_reports }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Annual Reports</p>
            <span class="i-icon"><i class="fa fa-book-open"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'MEMORANDUM' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.memorandum }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Memorandum</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'MEMORANDUMCIRCULAR' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.memorandum_circular }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Memorandum Circular</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'IPCR' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.ipcr }}</h1>
            <p class="w-75" style="color: #FFFFFF;">IPCR</p>
            <span class="i-icon"><i class="fa fa-user-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'OPCR' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.opcr }}</h1>
            <p class="w-75" style="color: #FFFFFF;">OPCR</p>
            <span class="i-icon"><i class="fa fa-building"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'FILES201ACADEMIC' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.faculty_201_files }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Academic 201 Files</p>
            <span class="i-icon"><i class="fa fa-users"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'FILES201ADMINISTRATIVE' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.staff_201_files }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Administrative 201 Files</p>
            <span class="i-icon"><i class="fa fa-users"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'CT' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.ct }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Certificate of Title</p>
            <span class="i-icon"><i class="fa fa-certificate"/></span>
          </div>
        </router-link>
      </el-col>
    </el-row>
    <!-- End Records -->
    <el-row :gutter="20">
      <!-- Scholarship Secretary -->
      <!-- Minutes -->
      <el-col v-if="records_sch" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'SECSCHMINUTES' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.records_sch }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- End Scholarship Secretary -->
      <!-- Personal Board Secretary -->
      <!-- Minutes -->
      <el-col v-if="records_pb" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'SECPBMINUTES' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.records_pb }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- End Personal Board Secretary -->
      <!-- OUS Secretary -->
      <!-- Minutes -->
      <el-col v-if="records_ous" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'SECOUSMINUTES' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.minutes }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- Resolutions -->
      <el-col v-if="records_ous" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'SECOUSRESOLUTIONS' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.resolutions }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Resolutions</p>
            <span class="i-icon"><i class="fa fa-vote-yea"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- Annual Reports -->
      <el-col v-if="records_ous" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'OUSANNUALREPORTS' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.annual_reports }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Annual Reports</p>
            <span class="i-icon"><i class="fa fa-book-open"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- Memorandum -->
      <el-col v-if="records_ous" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'OUSMEMORANDUM' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.memorandum }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Memorandum</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- Memorandum Circular -->
      <el-col v-if="records_ous" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'OUSMEMORANDUMCIRCULAR' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.memorandum_circular }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Memorandum Circular</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- End OUS Secretary -->
      <!-- Memo Secretary -->
      <!-- Memorandum -->
      <el-col v-if="records_memo_sec" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'SECMEMORANDUM' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.sec_memorandum }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Memorandum</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- Memorandum Circular -->
      <el-col v-if="records_memo_sec" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link
          v-loading="loading"
          :to="{ name:'SECMEMORANDUMCIRCULAR' }"
          class="after-loop-item card border-0 card-guides shadow-lg"
          style="margin-top: 25px;">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1 style="color: #FFFFFF;">{{ counts.sec_memorandum_circular }}</h1>
            <p class="w-75" style="color: #FFFFFF;">Memorandum Circular</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <!-- End Memo Secretary -->
    </el-row>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { RecordsAnalytics } from '@/api/records/records_analytics'

export default {
  name: 'Dashboard',
  data() {
    return {
      counts: {},
      loading: false,
      role: null,
      records: false,
      records_sch: false,
      records_pb: false,
      records_ous: false,
      records_memo_sec: false,
      view_file: null,
      view_attach_form: false
    }
  },
  created() {
    this.checkRole()
  },
  methods: {
    checkRole() {
      this.$store.getters.user.currentUser.roles.forEach(role => {
        if (role.name === 'records') {
          this.records = true
          this.role = role.name
        } else if (role.name === 'records_sch') {
          this.records_sch = true
          this.role = role.name
        } else if (role.name === 'records_pb') {
          this.records_pb = true
          this.role = role.name
        } else if (role.name === 'records_ous') {
          this.records_ous = true
          this.role = role.name
        } else if (role.name === 'records_memo_sec') {
          this.records_memo_sec = true
          this.role = role.name
        }
      })
      this.getRecordsAnalytics()
    },
    getRecordsAnalytics() {
      this.loading = true
      RecordsAnalytics({ role: this.role }, 'get').then(res => {
        this.counts = res.data.counts
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    viewFile() {
      this.view_file = this.$store.getters.base_url + '/records/records_manual/download/HRMIS_Records_Manual.pdf'
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  @import '../../styles/z-theme.css';
  .i-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    left: 1rem;
    color: #FFFFFF;
    transition: .15s all ease;
  }
</style>
